import React, { useContext, useState, useEffect, useRef } from "react";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ScreenSizeContext = React.createContext(null);

const ScreenSizeProvider = ({ children }) => {
    const theme = useTheme();
    const [ screenSizes, updateScreenSizes ] = useState({});

    const newScreenSizes = {
        isXS: useMediaQuery(theme.breakpoints.only('xs')), // < 959.95,
        isSmall: useMediaQuery(theme.breakpoints.only('sm')), // < 959.95,
        isMedium: useMediaQuery(theme.breakpoints.only( 'md')), // 960 - 1279.95
        isLarge: useMediaQuery(theme.breakpoints.up('lg')) // > 1280
    };

    if(
        screenSizes.isXS !== newScreenSizes.isXS ||
        screenSizes.isSmall !== newScreenSizes.isSmall ||
        screenSizes.isMedium !== newScreenSizes.isMedium ||
        screenSizes.isLarge !== newScreenSizes.isLarge
    ) setTimeout(() => updateScreenSizes(newScreenSizes), 100);

    return (
        <ScreenSizeContext.Provider
            value={{
                ...screenSizes
            }}
        >
            {children}
        </ScreenSizeContext.Provider>
    );
};

const useScreenSize = () => {
    const Event = useContext(ScreenSizeContext);
    if (Event == null) {
        throw new Error("useScreenSize() called outside of a ScreenSizeProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { ScreenSizeProvider, useScreenSize };
