import React, { useContext, useState, useEffect, useRef, Suspense } from "react";
import {
    Routes as NavigationRoutes,
    Route,
} from "react-router-dom";
import ReactSpinner from "@mui/material/CircularProgress";
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { Routes } from "../Constants/Routes";
import NavigationDrawer from "../Components/NavigationDrawer";
import { useRouting } from "../Providers/RoutingProvider";
import {useScreenSize} from "../Providers/ScreenSizeProvider";
import useStyles from "../Styles/ApplicationUI"
import Logo from "../Components/SVGIcons/Logo";
import Colorize from "@mui/icons-material/Colorize";
import Refresh from "@mui/icons-material/Refresh";
import {Icon} from "@mui/material";
import AsyncButton from "../Components/AsyncButton";
import {useCustomTheme} from "../Providers/CustomThemeProvider";

const ApplicationUI = ({ children }) => {
    const { getRandomTheme } = useCustomTheme();
    const classes = useStyles();
    const theme = useTheme();
    const { isXS, isSmall } = useScreenSize();
    const [open, setOpen] = useState(!(isXS || isSmall));
    const { currentRoute } = useRouting();
    const RouteRightIcon = currentRoute?.RightIcon

    useEffect(() => {
        if ((isXS || isSmall) && open) {
            setOpen(false)
        }
    }, [isXS, isSmall])

    return (
        <div>
            <CssBaseline />
            <AppBar
                position="fixed"
                color={'secondary'}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar color={'secondary'}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(true)}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon sx={{ color: theme.palette.secondary.contrastText }} />
                    </IconButton>
                    {!open && <Logo style={{ width: 55, height: 55, margin: theme.spacing(0, 2) }} bodyColor={theme.palette.secondary.contrastText} />}
                    <Typography sx={{ color: theme.palette.secondary.contrastText }} className={classes.appBarTitle} variant="h6" noWrap>
                        { currentRoute?.appBarTitle || currentRoute?.title }
                    </Typography>
                    {RouteRightIcon &&
                        <RouteRightIcon />
                    }
                    <AsyncButton
                        onClick={() => {
                            return getRandomTheme()
                        }}
                        variant={'text'}
                        LeftIcon={() => (<Colorize sx={{ color: theme.palette.secondary.contrastText }} />)}
                        label={"Change Theme"}
                        sx={{ color: theme.palette.secondary.contrastText }}
                    />
                </Toolbar>
            </AppBar>
            <NavigationDrawer open={open} setOpen={setOpen} />
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <NavigationRoutes>
                    {Object.values(Routes).map(({ title, path, exact = false, Scene }) => {
                        return (
                            <Route exact={exact} path={path} element={
                                <Suspense fallback={() => <ReactSpinner />}>
                                    <Scene isNavbarOpen={open}/>
                                </Suspense>
                            }/>
                        )
                    })}
                </NavigationRoutes>
            </main>
        </div>
    );
};

export default ApplicationUI;
