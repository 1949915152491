import React from 'react'
import SvgIcon from "@mui/material/SvgIcon";

const Logo = ({ bodyColor = 'black', hairColor = '#A26B19', style = {}} = {}) => {
    return (
        <SvgIcon
            fontSize={'large'}
            width="55"
            height="54"
            viewBox="0 0 55 54"
            style={style}
        >
            <path fill-opacity={0} d="M3.12428 52.2065C3.63278 52.1373 4.38487 50.8403 4.74274 50.3854C5.43328 49.5076 6.14881 47.3715 6.21528 46.1902C6.38548 43.1653 6.46733 40.1876 6.46733 37.1495C6.46733 34.4084 6.03038 31.4734 5.27338 28.9218C4.66206 26.8612 3.55421 25.0693 3.07121 22.938C2.29653 19.5197 -0.214526 12.4006 3.65492 10.3526C6.64009 8.7726 10.2036 8.59648 13.4055 8.59648C16.7834 8.59648 20.2503 10.2707 22.4132 13.6372C24.5925 17.0292 25.6695 21.5462 25.3185 25.8486C25.1095 28.4104 22.2362 28.7981 20.609 29.3771C18.1006 30.2697 15.6382 30.2552 13.0473 30.2552C11.9425 30.2552 11.0753 29.8095 10.0492 29.6698C8.79012 29.4983 6.42776 28.3432 5.45911 27.3934C4.86826 26.814 1.27099 24.3664 3.85391 23.3771C5.83677 22.6176 7.34302 22.06 9.4522 22.06C11.7929 22.06 14.4447 23.1423 16.4832 24.5478C17.9171 25.5364 19.1359 26.9411 20.5559 27.9137C22.4801 29.2317 24.7009 31.4324 25.4379 34.0601C26.1318 36.5343 26.6021 39.2749 26.5257 41.8975C26.4693 43.8334 24.2907 45.5892 23.1826 46.6455C20.9514 48.7726 18.5018 50.0595 15.7934 50.8895C13.6516 51.5458 11.4272 51.3285 9.21342 51.3285C7.53568 51.3285 6.27525 50.2843 4.67641 49.8488C3.06926 49.411 3.08181 45.7675 1.69154 45.7675" stroke={bodyColor} stroke-width="3" stroke-linecap="round"/>
            <path fill-opacity={0} d="M9.5716 18.2551C8.45474 18.084 10.1338 16.5106 10.288 16.3689C10.5648 16.1144 10.8684 17.6376 10.7125 17.9624C10.544 18.3135 9.43853 18.5478 9.09402 18.5478" stroke={bodyColor} stroke-width="3" stroke-linecap="round"/>
            <path fill-opacity={0} d="M15.5413 18.5478C15.5413 17.4719 16.022 16.4373 17.0802 16.8079C17.2837 16.8792 17.2976 18.0212 17.1598 18.1901C17.0037 18.3814 16.0201 18.5478 15.7801 18.5478" stroke={bodyColor} stroke-width="3" stroke-linecap="round"/>
            <path fill-opacity={0} d="M4.55684 9.18185C3.87603 9.18185 2.45358 9.60802 2.40773 8.59648C2.36578 7.67092 1.83677 6.69114 1.6781 5.81597C1.21682 3.27171 4.07165 3.91352 5.512 3.91352C7.50518 3.91352 8.24332 7.06817 7.88663 9.0355C7.58817 10.6817 6.33968 11.3195 5.03442 11.2306C3.92553 11.1551 3.60168 9.36368 3.60168 8.28753C3.60168 7.08614 3.26479 5.57382 4.19866 4.93791C4.96316 4.41733 5.85032 4.49889 6.70595 4.49889C8.48284 4.49889 10.2766 8.88916 8.24482 8.88916C6.3071 8.88916 6.22837 5.93661 6.22837 4.22246C6.22837 2.77863 8.34879 0.384817 9.51836 1.76716C10.4428 2.85982 10.8074 4.7768 11.243 6.18996C11.4399 6.82902 12.0453 7.71842 11.2164 7.71842C10.3693 7.71842 9.39826 7.90666 9.09385 6.69402C8.75637 5.34965 8.96547 4.18627 9.99594 3.39319C10.7396 2.82087 13.135 2.05618 13.8166 3.1005C15.1405 5.12899 11.8127 5.97475 10.7654 5.79971C9.97995 5.66843 9.33264 5.45374 9.33264 4.33628C9.33264 3.63849 9.15418 2.96186 9.69082 2.59644C10.3424 2.15272 11.5789 2.45009 12.3042 2.45009C13.7834 2.45009 14.3053 3.67687 15.0636 5.08426C15.6984 6.26246 16.7866 9.78696 14.4003 8.01111C13.2982 7.19096 12.018 3.57534 13.2859 2.30375C14.5787 1.00734 16.9691 0.62976 18.1148 2.45009C18.649 3.29895 19.6781 6.44528 18.2873 6.82411C17.5998 7.01136 15.78 7.04955 15.78 5.81597C15.78 4.20569 16.081 3.37053 17.6372 3.32815C19.851 3.26785 20.0782 3.52439 20.0782 6.18996C20.0782 8.38455 20.3317 9.47453 18.2873 9.47453C16.7818 9.47453 15.5412 9.17873 15.5412 7.13305C15.5412 5.39007 16.6103 4.49889 17.9821 4.49889C19.6427 4.49889 20.3558 5.41088 21.0333 7.27939C21.4297 8.37241 21.0913 9.18185 20.0782 9.18185" stroke={hairColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M29.8687 6.54768C31.8861 6.54768 34.018 6.32824 35.9579 6.85662C36.8133 7.08962 37.452 7.42573 38.3458 7.42573C39.0978 7.42573 39.794 8.24464 40.6143 8.30379C42.1905 8.41743 43.4023 10.1373 44.8064 10.629C46.5471 11.2386 48.4296 12.4424 49.2107 14.5965C49.9174 16.5455 50.441 18.4069 51.1741 20.3039C52.4432 23.5878 52.5538 26.8777 52.5538 30.5478C52.5538 32.3058 52.4774 34.2107 52.0231 35.8812C51.7137 37.0189 51.0512 38.1997 50.8955 39.3446C50.4946 42.2933 49.1704 43.7355 47.6453 45.6049C45.017 48.8264 41.803 50.1577 38.107 50.1577C35.6693 50.1577 34.9013 48.7586 33.3975 46.7106C32.4505 45.4208 31.1396 41.9974 31.0627 40.2064C30.9068 36.5751 28.9432 29.6698 33.8088 29.6698C35.5303 29.6698 37.3451 29.9625 38.9427 29.9625C40.4722 29.9625 42.8712 29.4432 44.1961 30.2552" stroke={bodyColor} stroke-width="3" stroke-linecap="round"/>
            <path fill-opacity={0} d="M39.3426 28.3186C39.3426 27.1288 39.5397 25.6154 39.3286 24.4509C39.2157 23.8281 39.0902 23.4222 39.0902 22.749C39.0902 22.4428 38.3584 23.0395 38.0804 23.0585C37.4145 23.1038 32.5544 23.3285 32.5266 22.749C32.4044 20.2014 32.7791 17.9001 32.7791 15.323C32.7791 13.7621 32.3502 13.4664 33.6626 13.4664C34.6724 13.4664 35.6822 13.4664 36.6919 13.4664C38.6274 13.4664 40.5628 13.4664 42.4982 13.4664C42.8103 13.4664 43.3619 13.0532 43.3817 13.4664C43.4196 14.2559 43.2647 13.4664 43.8866 13.4664C44.9006 13.4664 44.8964 15.2444 44.8964 16.4059C44.8964 17.1947 46.1942 22.749 45.0226 22.749C42.7927 22.749 40.5628 22.749 38.3328 22.749" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M34.2937 17.4889C34.3481 16.9557 34.8988 17.1648 35.1072 17.4201C35.5017 17.9037 35.539 17.6916 35.8645 17.2482C36.6671 16.1552 37.1194 18.0599 37.7719 17.4201C38.0611 17.1365 38.3772 17.2627 38.6414 16.9388C39.0101 16.4869 38.8593 17.1036 39.1463 17.4201C39.2412 17.5249 41.0148 17.1795 41.3622 17.1795" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M34.5462 20.5831C34.656 19.3717 35.673 19.403 36.1169 20.2737C36.6463 21.3118 37.5264 19.9643 38.0804 19.9643C39.0773 19.9643 38.9614 21.0724 39.9036 20.033C40.5939 19.2715 41.1686 19.9883 41.867 20.2737" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M33.4633 28.7731C33.4633 28.0898 33.1868 27.0335 33.3287 26.4247C33.3862 26.1781 35.1005 26.3277 35.3872 26.3277C36.5528 26.3277 37.6576 25.9783 38.8074 25.9783C40.0212 25.9783 41.2708 25.8036 42.4413 25.8036C43.1926 25.8036 43.7532 25.5901 43.4706 26.7158C43.287 27.4472 43.1539 28.1911 43.1539 28.9478" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M34.8884 27.8997H35.0306" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M36.171 27.8997C36.171 27.5965 36.171 27.5965 36.171 27.8997" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M37.311 27.8997C37.5594 27.8997 37.5954 27.8555 37.5954 27.5504" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M40.1612 26.8517V27.0264" stroke={bodyColor} stroke-linecap="round"/>
            <path fill-opacity={0} d="M41.1587 27.0264C41.1793 27.2284 41.3577 27.4807 41.4431 27.3757" stroke={bodyColor} stroke-linecap="round"/>
        </SvgIcon>
    )
}

export default Logo
