import HomeSharp from "@mui/icons-material/HomeSharp";
import SmartToy from "@mui/icons-material/SmartToy";
import VideogameAsset from "@mui/icons-material/VideogameAsset";

const routes = {
    HOME: {
        key: 'HOME',
        title: 'Home',
        appBarTitle: '',
        navTitle: 'Home',
        path: '/',
        exact: true,
        NavIcon: HomeSharp
    },
    DAILY_HAIKU: {
        key: 'DAILY_HAIKU',
        title: 'Daily Haiku',
        appBarTitle: '',
        navTitle: 'Daily Haiku',
        path: '/dailyHaiku',
        exact: true,
        NavIcon: SmartToy
    },
    GODOT: {
        key: 'GODOT',
        title: 'Godot',
        appBarTitle: '',
        navTitle: 'Godot',
        path: '/godot',
        exact: true,
        NavIcon: VideogameAsset
    },
}

export default routes