import _ from 'lodash';
import React, { lazy } from 'react'
import HomeSharp from "@mui/icons-material/HomeSharp";
import SmartToy from "@mui/icons-material/SmartToy";
import VideogameAsset from "@mui/icons-material/VideogameAsset";
import _routes from './_routes';
const Home = lazy(() => import('../Scenes/Home/Home'))
const DailyHaiku = lazy(() => import('../Scenes/DailyHaiku/DailyHaiku'))
const GodotGames = lazy(() => import('../Scenes/GodotGames/GodotGames'))
export const Routes = {
    [_routes.HOME.key]: {
        ..._routes.HOME,
        Scene: Home,
    },
    [_routes.DAILY_HAIKU.key]: {
        ..._routes.DAILY_HAIKU,
        Scene: DailyHaiku,
    },
    [_routes.GODOT.key]: {
        ..._routes.GODOT,
        Scene: GodotGames,
    },
}

const NavigationRoutes = [
    Routes.HOME,
    Routes.DAILY_HAIKU,
    Routes.GODOT,
];

export const findRoute = (path) => {
    return _.find(Routes, { path })
}

export default NavigationRoutes
