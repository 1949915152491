import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import logo from './logo.svg';
import './App.css';

import { ThemeProvider } from "@mui/material/styles";
import Theme from "./Constants/Theme";
import {ScreenSizeProvider} from "./Providers/ScreenSizeProvider";
import {RoutingProvider} from "./Providers/RoutingProvider";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import ApplicationUI from "./Scenes/ApplicationUI";
import {CustomThemeProvider, useCustomTheme} from "./Providers/CustomThemeProvider";
import {useEffect, useState} from "react";
function ThemeableApp() {
    const {
        getCurrentTheme,
        customPalette
    } = useCustomTheme();
    const [currentTheme, updateCurrentTheme] = useState(getCurrentTheme());

    useEffect(() => {
        updateCurrentTheme(getCurrentTheme())
    }, [customPalette])

    return (
      <ThemeProvider theme={currentTheme}>
          <Router>
              <ScreenSizeProvider>
                  <RoutingProvider>
                      <ApplicationUI />
                  </RoutingProvider>
              </ScreenSizeProvider>
          </Router>
      </ThemeProvider>
    );
}

function App() {
    return (
        <CustomThemeProvider>
            <ThemeableApp />
        </CustomThemeProvider>
    )
}

export default App;
