const _theme = {
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#7f898c',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#737c7f',
            // dark: will be calculated from palette.secondary.main,
        },
        info: {
            main: '#e7f9ff',
        },
        success: {
            main: '#acbbbf'
        },
        onPrimary: {
            main: '#ffffff'
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
}
export default _theme;