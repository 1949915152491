import React, {useState} from "react";
import {
    useNavigate,
    useResolvedPath
} from "react-router-dom";
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import JavascriptIcon from '@mui/icons-material/Javascript';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useStyles, { drawerWidth } from "../Styles/ApplicationUI";

import Routes from "../Constants/Routes";
import Logo from "./SVGIcons/Logo";
import {useRouting} from "../Providers/RoutingProvider";

const NavigationItem = (props) => {
    const {
        key,
        style = {},
        title,
        navTitle,
        path,
        NavIcon,
        navIconStyle = {},
        routes = [],
        isDefaultOpen = false,
        subRouteIndex = 0,
        onClick,
        isOutsideCRA,
        index
    } = props;
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const [ isOpen, updateIsOpen ] = useState(isDefaultOpen);
    const { currentRoute } = useRouting();

    if (routes.length) {
        return (
            <React.Fragment>
                <ListItem className={clsx({ [classes.navigationItemSelected]: currentRoute?.path === path })}
                          button
                          key={title}
                          onClick={() => updateIsOpen(!isOpen)}
                >
                    {!!NavIcon && <ListItemIcon><NavIcon color={'red'} /></ListItemIcon>}
                    <ListItemText primary={navTitle || title} />
                    {isOpen ? <ExpandMore /> : <ChevronRightIcon />}
                </ListItem>
                {isOpen && routes?.map((route, index) => {
                    return (
                        <NavigationItem key={`${title}-${index}`} {...route} subRouteIndex={subRouteIndex + 1}/>
                    )
                })}
            </React.Fragment>
        )
    }

    let Wrapper = React.Fragment;

    if (isOutsideCRA) {
        Wrapper = ({ children }) => {
            return (
                <a
                    style={{ textDecoration: "none", color: theme.palette.text.primary }}
                    href={path}
                >
                    {children}
                </a>
            )
        }
    }

    return (
        <Wrapper>
            <ListItem key={key || title}
                      className={clsx({ [classes.navigationItemSelected]: currentRoute?.path === path })}
                      style={subRouteIndex ? { paddingLeft: 20 * subRouteIndex, ...style } : style}
                      button
                      onClick={() => !isOutsideCRA && navigate(path)}
            >
                {!!NavIcon && <ListItemIcon><NavIcon sx={{ color: currentRoute?.path === path ? theme.palette.secondary.contrastText : 'inherit', ...navIconStyle }} /></ListItemIcon>}
                <ListItemText primary={navTitle || title} />
            </ListItem>
        </Wrapper>
    )
}

const NavigationDrawer = ({ open, setOpen }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={() => setOpen(false)}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <Logo style={{ width: 55, height: 55 }} />
            </div>
            <Divider />
            <List>
                {Routes.map((route, index) => (
                    <NavigationItem key={`route-${index}`} index={index} {...route } />
                ))}
                <NavigationItem
                    key={`route-nextjs`}
                    index={Routes.length}
                    style={{ paddingLeft: 8 }}
                    NavIcon={JavascriptIcon}
                    navIconStyle={{ fontSize: 40 }}
                    title={'NextJS'}
                    path={'/next'}
                    isOutsideCRA={true}
                />
            </List>
        </Drawer>
    );
};

export default NavigationDrawer;
