import React, { useContext, useState, useEffect, useRef } from "react";
import Theme from '../Constants/Theme';
import {createTheme, responsiveFontSizes} from "@mui/material/styles";
import APIRequest from "../Services/APIRequest";
import ColorScheme from "color-scheme";
const CustomThemeContext = React.createContext(null);

const CustomThemeProvider = ({ children }) => {
    const [ isCustom, updateIsCustom ] = useState(false);
    const [ customPalette, updateCustomPalette ] = useState();

    const generateCustomTheme = (customPalette) => {
        let theme = createTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: customPalette[0],
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    // contrastText: '#ffffff',
                },
                secondary: {
                    main: customPalette[1],
                    // dark: will be calculated from palette.secondary.main,
                    // contrastText: '#ffffff',
                },
                info: {
                    main: customPalette[2],
                    // contrastText: '#ffffff',
                },
                success: {
                    main: customPalette[3]
                },
                onPrimary: {
                    main: '#ffffff'
                },
                // Used by `getContrastText()` to maximize the contrast between
                // the background and the text.
                contrastThreshold: 3,
                // Used by the functions below to shift a color's luminance by approximately
                // two indexes within its tonal palette.
                // E.g., shift from Red 500 to Red 300 or Red 700.
                tonalOffset: 0.2,
            },
        });
        theme = responsiveFontSizes(theme)
        return theme
    }

    const getCurrentTheme = () => {
        if (customPalette) {
            return generateCustomTheme(customPalette)
        }

        return Theme;
    }

    const getRandomTheme = (tries = 0) => {
        try {
            if (tries > 5) return;
            let possibleSchemes = [
                'mono',
                'contrast',
                'triade',
                'tetrade',
                'analogic',
            ];

            let possibleVariations = [
                'pastel',
                'soft',
                'light',
                'hard',
                'pale',
            ]

            let randomScheme = possibleSchemes[Math.floor((Math.random()*possibleSchemes.length))]
            let randomVariation = possibleVariations[Math.floor((Math.random()*possibleVariations.length))]

            let scheme = new ColorScheme();
            scheme.from_hex(Math.floor(Math.random()*16777215).toString(16))
                .scheme(randomScheme)
                .variation(randomVariation)

            updateCustomPalette(scheme.colors().map((color) => `#${color}`));
        } catch(e) {
            getRandomTheme(tries + 1)
        }
    }

    const restoreDefaultTheme = () => {
        updateCustomPalette();
    }

    const letsParty = (totalParty = 100, currentParty = 0) => {
        if (currentParty < totalParty) {
            try {
                getRandomTheme();
            } catch(err) {

            }
            setTimeout(() => {
                letsParty(totalParty, currentParty + 1)
            }, Math.random() * 300)
        }
    }

    return (
        <CustomThemeContext.Provider
            value={{
                getCurrentTheme,
                getRandomTheme,
                customPalette,
                letsParty
            }}
        >
            {children}
        </CustomThemeContext.Provider>
    );
};

const useCustomTheme = () => {
    const Event = useContext(CustomThemeContext);
    if (Event == null) {
        throw new Error("useCustomTheme() called outside of a CustomThemeProvider?"); // an alert is not placed because this is an error for the developer not the user
    }
    return Event;
};

export { CustomThemeProvider, useCustomTheme };
